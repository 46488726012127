:root {
  --primary-color: #2382AB;
  --secondary-color: #FD6421;
  --grey-color: #808080;
  --green-color: #4CAF50;
  --blue-shade-1: #E6F2F8;
  --blue-shade-2: #CCE5F1;
  --blue-shade-3: #99CCE3;
  --blue-shade-4: #66B2D4;
  --blue-shade-5: #3399C6;
  --blue-shade-6: #007FB8;
  --blue-shade-7: #006699;
  --blue-shade-8: #004D73;
  --blue-shade-9: #00334D;
  --orange-shade-1: #FFEDE6;
  --orange-shade-2: #FFDACC;
  --orange-shade-3: #FFB599;
  --orange-shade-4: #FF9166;
  --orange-shade-5: #FF6C33;
  --orange-shade-6: #FD6421;
  --orange-shade-7: #CC511A;
  --orange-shade-8: #993D14;
  --orange-shade-9: #66280D;
  --grey-shade-1: #F2F2F2;
  --grey-shade-2: #E6E6E6;
  --grey-shade-3: #CCCCCC;
  --grey-shade-4: #B3B3B3;
  --grey-shade-5: #999999;
  --grey-shade-6: #808080;
  --grey-shade-7: #666666;
  --grey-shade-8: #4D4D4D;
  --grey-shade-9: #333333;
  --green-shade-1: #E8F5E9;
  --green-shade-2: #C8E6C9;
  --green-shade-3: #A5D6A7;
  --green-shade-4: #81C784;
  --green-shade-5: #66BB6A;
  --green-shade-6: #4CAF50;
  --green-shade-7: #388E3C;
  --green-shade-8: #2E7D32;
  --green-shade-9: #1B5E20;
}