@import url('../../utils/colors/generated-variables.css');
.services-containter{
  background: var(--grey-shade-8);
}

p {
  display: flex;
  justify-content: center;
  color: black;
}

.homepage-title {
  display: flex;
  text-align: center;
  font-size: 36px;
  font-weight: 800;
  text-transform: capitalize;
}

.btn-primary {
  background-color: var(--blue-shade-8);
  color: white;
  text-decoration: none;
}

.btn-primary:hover {
  background-color: var(--blue-shade-9); /* Darker shade for hover */
}

.btn-primary:active {
  background-color: var(--blue-shade-9); /* Even darker shade for active */
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

@keyframes shake {
  0%, 100% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(0deg) translateY(-1px);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: rotate(-10deg);
  }
  20%, 40%, 60%, 80% {
    transform: rotate(10deg);
  }
}

.shaking-icon {
  animation: shake 7.5s ease-in-out infinite;
  color: var(--orange-shade-6);
}

