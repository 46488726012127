@import url('./utils/colors/generated-variables.css');

.navbar {
  background-color: var(--blue-shade-8);
  padding: 0;
}

.navbar-brand {
  max-width: 150px;
  padding: 0;
}

.container-fluid {
  flex-wrap: nowrap !important;
  padding: 0;
}

.navbar-nav{
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  overflow-x: auto; /* Ensure horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Enable momentum scrolling on iOS */
}

.navbar-nav::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.navbar-nav {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


/* Styles for nav items */
.nav-item {
  margin-right: 1rem;
}

.nav-link {
  text-decoration: none;
  color: white;
  text-transform: uppercase; 
}
.nav-link:hover {
    color: var(--orange-shade-6);
}

/* Media query for tablet and desktop devices */
@media (min-width: 768px) {


  .container-fluid {
    height: 130px;
  }

  .navbar {
    padding: 0;
  }

  .navbar-brand {
    max-width: 250px;
  }

  .navbar-nav {
    justify-content: flex-start;
    overflow-x: visible; /* Disable horizontal scrolling */
  }
}