@import url('./utils/colors/generated-variables.css');

a {
  text-decoration: none;
  color: white;
}

.copyright {
  font-size: 8px;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-content > div {
  flex: 1;
  min-width: 200px; /* Ensure a minimum width for each column */
}


.footer-container {
  background-color: var(--orange-shade-);
  color: black;
}

.footer-container a {
  color: black;
  text-decoration: none;
}

.footer-icon:hover {
  color: var(--blue-shade-6);
  transition: color 0.3s ease-in-out;
}

.footer-icon:active {
  color: var(--blue-shade-6);
}

.footer-link-with-icon:hover {
  color: var(--blue-shade-6);
  transition: color 0.3s ease-in-out;
}

.link-text {
  font-size: calc(1.rem + 0.6vw);
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }
}