@import url('../../utils/colors/generated-variables.css');

h3 {
  text-transform: capitalize;
  color: white;
}

.book-now-arrow {
  color: var(--secondary-color)
}

.link {
  text-decoration: none;
  color: white;
  text-transform: uppercase; 
}
.link:hover {
    color: black;
}

.image-wrapper {
  width: 100%; /* Ensure the wrapper takes the full width of its parent */
  height: 400px; /* Maintain the aspect ratio */
}

.service-image {
  width: 100%; /* Make the image take the full width of its parent */
  height: 100%; /* Maintain the aspect ratio */
  margin: 0; 
  img {
    clip-path: polygon(0 0, 100% 0%, 100% 74%, 0% 100%); /* Create a rhombus shape */
  }
}

.service-summary {
  color: white;
  height: 125px;
}

@media (min-width: 768px) {
  .service-image {
    padding: 2px;
    max-width: 325px;
    margin-left: 0;
  }
}